import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import "./styles/menu.scss"

const Menu = ({ menu, openMenu, forwardPage, backPage, index }) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      wpgraphql {
        generalSettings {
          title
          url
          language
          timezone
          timeFormat
        }
        mediaItem(idType: SLUG, id: "isotipo") {
          id
          guid
          title
          uri
          sourceUrl
        }
        menu(id: "TWVudToy") {
          id
          menuId
          name
          menuItems {
            nodes {
              id
              label
              title
              url
            }
          }
        }
      }
    }
  `)

  const changeMenu = async (move) => {
    move = parseInt(move.replace("http://", ""));
    if (move > index.count) {
      await forwardPage(move)
    }

    if (move < index.count) {
      await backPage(move)
    }
  }

  return (
    <div className={menu ? "menuBox menuOut" : "menuBox menuIn"}>
      <div className="menuContainer">
        <div className="menuHeader">
          <div className="header-link">
            {data.wpgraphql.mediaItem ? (
              <img
                className="banner-image"
                alt={data.wpgraphql.mediaItem.title}
                src={data.wpgraphql.mediaItem.sourceUrl}
              />
            ) : (
              <h1>{data.wpgraphql.generalSettings.title}</h1>
            )}
          </div>
          <div onClick={openMenu} className="menu">
            <h1>Close</h1>
          </div>
        </div>
        {data.wpgraphql.menu.menuItems.nodes.map((value, index) => {
          return (
            <p className="menuItem" key={index} onClick={() => changeMenu(value.url)}>
              {value.label}
            </p>
          )
        })}
      </div>
    </div>
  )
}

export default Menu
