/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import "./styles/layout.scss"
import Menu from "./menu"
import Facebook from "../assets/images/facebook.png"
import Whatsapp from "../assets/images/whatsapp.png"
import Instagram from "../assets/images/instagram.png"

const Layout = ({ children, upPage, downPage, index }) => {
  const [scrolled, setScrolled] = useState(false)
  const [menu, setMenu] = useState(true)
  const [initScroll, setInitScroll] = useState(0)

  const ScrollIn = async event => {
    event.persist()

    if (event.deltaY > 150 && !scrolled) {
      setScrolled(true)
      await upPage()
    }

    if (event.deltaY < -150 && !scrolled) {
      setScrolled(true)
      await downPage()
    }

    if (scrolled) setScrolled(false)
  }

  const openMenu = () => {
    console.log(!menu)
    setMenu(!menu)
  }

  const TouchScroll = async event => {
    let diff = event.touches[0].clientY - initScroll;

    if (diff < -300 && !scrolled) {
      setScrolled(true)
      await upPage()
    }

    if (diff > 300 && !scrolled) {
      setScrolled(true)
      await downPage()
    }
    if (scrolled) setScrolled(false)
  }

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          margin: `0 auto`,
          width: "100%",
        }}
        onWheel={ScrollIn}
        onTouchStart={events => {
          setInitScroll(events.touches[0].clientY)
        }}
        onTouchMove={TouchScroll}
      >
        <div
          className={menu ? "pageIndicator pageIn" : "pageIndicator pageOut"}
        >
          <p className="indexPage">
            {index
              ? index.count + 1 < 10
                ? "0" + (index.count + 1)
                : index.count + 1
              : "0"}
          </p>
          <div className="pageIndicatorLine"></div>
          <p className="secondIndexPage">
            {index
              ? index.max + 1 < 10
                ? "0" + (index.max + 1)
                : index.max + 1
              : "0"}
          </p>
        </div>
        <Header openMenu={openMenu} />
        <main
          className={menu ? "pageMenuIn" : "pageMenuOut"}
          style={{ width: "100%" }}
          style={index ? index.count === index.max ? { height: "95%" } : null : null}
        >
          {children}
        </main>
        <footer style={index ? index.count < index.max ? { display: "none" } : null : null}>
          <div className="copyright"><p>Copyright © 2020. Copyright Expomorro C,A. Todos los derechos reservados</p></div>
          <div className="network">
            <img src={Whatsapp} alt="whatsapp" ></img>
            <img src={Facebook} alt="facebook" ></img>
            <img src={Instagram} alt="instagram"></img>
          </div>
        </footer>
      </div>
      <Menu menu={menu} openMenu={openMenu} backPage={downPage} forwardPage={upPage} index={index} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
