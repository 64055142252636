import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import "./styles/header.scss"

const Header = ({openMenu}) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      wpgraphql {
        generalSettings {
          title
          url
          language
          timezone
          timeFormat
        }
        menu(id: "TWVudToy") {
          id
          menuId
          name
          menuItems {
            nodes {
              id
              label
              title
            }
          }
        }
        mediaItem(idType: SLUG, id: "isotipo") {
          id
          guid
          title
          uri
          sourceUrl
        }
      }
    }
  `)
  return (
    <header className="header">
      <div className="header-link">
        <Link to="/" className="link">
          {data.wpgraphql.mediaItem ? (
            <img className="banner-image" alt={data.wpgraphql.mediaItem.title}  src={data.wpgraphql.mediaItem.sourceUrl} />
          ) : (
            <h1>{data.wpgraphql.generalSettings.title}</h1>
          )}
        </Link>
      </div>
      <div onClick={openMenu} className="menu">
        <h1>Menu</h1>
      </div>
    </header>
  )
}

export default Header
